import { API, Auth } from "aws-amplify";
import React from "react";
import { useNavigate } from "react-router-dom";
import config from "../config.json";
import { ThreeDots } from "react-loader-spinner";
import toast from "react-hot-toast";

export default function MemberList(props) {
  const navigate = useNavigate();
  return <MemberListComp {...props} navigate={navigate} />;
}

class MemberListComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: config.stage,
      user: "",
      myinit: {},
      loading: false,
      auth_no: "",
      site_id: "0",
      name: "",
      tel: "",
      address: "",
      members: [],
    };
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = await user.signInUserSession.idToken.jwtToken;
    const myinit = await {
      headers: {
        Authorization: token,
      },
    };

    await this.setState({
      token: token,
      myinit: myinit,
    });

    this.getMembers();
  };

  getMembers = async () => {
    this.setState({ loading: true });

    const myinit = {
      headers: this.state.myinit.headers,
      queryStringParameters: {
        auth_no: this.state.auth_no,
        site_id: this.state.site_id,
        name: this.state.name,
        tel: this.state.tel,
        address: this.state.address,
      },
    };

    await API.get(this.state.api_name, "/members", myinit)
      .then((r) => {
        this.setState({
          members: r,
        });
      })
      .catch((e) => {
        toast.error("会員データの取得エラー");
      });

    this.setState({ loading: false });
  };

  getCsv = async () => {
    const myinit = {
      headers: this.state.myinit.headers,
      queryStringParameters: {
        auth_no: this.state.auth_no,
        site_id: this.state.site_id,
        name: this.state.name,
        tel: this.state.tel,
        address: this.state.address,
      },
    };
    await API.get(this.state.api_name, "/members/csv", myinit)
      .then((r) => {
        const a = document.createElement("a");
        a.href = r;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((e) => {
        toast.error("検査場データのCSV出力エラー");
      });
  };

  handleAuthNo = (event) => {
    this.setState({
      auth_no: event.target.value,
    });
  };

  handleSiteId = (event) => {
    this.setState({
      site_id: event.target.value,
    });
  };

  handleName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleTel = (event) => {
    this.setState({
      tel: event.target.value,
    });
  };

  handleAddress = (event) => {
    this.setState({
      address: event.target.value,
    });
  };

  searchOnClick = async (event) => {
    this.getMembers();
  };

  render() {
    const members_html = [];
    const fs = this.state.members;
    for (let i = 0; i < fs.length; i++) {
      members_html.push(
        <tr key={i}>
          <td style={{ width: "75px", textAlign: "center" }}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                this.props.navigate("/member_edit/" + fs[i].Member.id);
              }}
            >
              編集
            </button>
          </td>
          <td>{fs[i].Member.auth_no}</td>
          <td>{fs[i].Site.name}</td>
          <td>{fs[i].Member.name}</td>
          <td>{fs[i].Member.tel}</td>
          <td>
            {fs[i].Member.address_1}
            {fs[i].Member.address_2}
          </td>
        </tr>
      );
    }

    return (
      <div className="content-wrapper">
        {/* Content Wrapper. Contains page content */}
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">会員マスター</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body pt-2 pb-2">
                <div className="row">
                  <div className="col-lg-2" style={{ maxWidth: "250px" }}>
                    <label className="small">認証番号</label>
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        id="auth_no"
                        placeholder="※部分一致"
                        defaultValue={this.state.auth_no}
                        onChange={this.handleAuthNo}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: "200px" }}>
                    <label className="small">検査場</label>
                    <div className="form-group mb-0">
                      <select
                        className="form-control select2bs4"
                        defaultValue="0"
                        style={{ width: "100%" }}
                        defaultValue={this.state.site_id}
                        onChange={this.handleSiteId}
                      >
                        <option value="0">-</option>
                        <option value="1">沖縄本所</option>
                        <option value="2">宮古支所</option>
                        <option value="3">八重山支所</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: "250px" }}>
                    <label className="small">事業者名</label>
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="※部分一致"
                        defaultValue={this.state.name}
                        onChange={this.handleName}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: "250px" }}>
                    <label className="small">TEL</label>
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        id="tel"
                        placeholder="※部分一致"
                        defaultValue={this.state.tel}
                        onChange={this.handleTel}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: "250px" }}>
                    <label className="small">住所</label>
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        placeholder="※部分一致"
                        defaultValue={this.state.address}
                        onChange={this.handleAddress}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer pt-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group clearfix mb-0">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={this.searchOnClick}
                      >
                        検索
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={this.getCsv}
                      >
                        CSV出力
                      </button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group clearfix mb-0 text-right">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          this.props.navigate("/member_edit");
                        }}
                      >
                        新規登録
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-primary card-outline">
              <div className="card-body table-responsive">
                <table
                  id="member_datatable"
                  className="table table-sm table-bordered table-hover table-head-fixed text-nowrap"
                >
                  <thead>
                    <tr>
                      <th>編集</th>
                      <th>認証番号</th>
                      <th>検査場</th>
                      <th>事業者名</th>
                      <th>TEL</th>
                      <th>住所</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={this.state.loading}
                    />
                    {members_html}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
        {/* /.content-wrapper */}
      </div>
    );
  }
}
