import React from 'react';
import { Auth } from 'aws-amplify'

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
  }

  componentDidMount() {
    Auth.currentSession().then(data => {
      this.setState({
        user: data.idToken.payload.email
      });
    })
  }

  render() {
    const user = this.state.user;
    return (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/*<!-- Left navbar links -->*/}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" data-enable-remember="true" href="#" role="button"><i
                className="fas fa-bars"></i></a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          {/*<!-- Navbar Search -->*/}
          <li className="nav-item">
            <a className="nav-link" data-widget="" href="#" role="button">
             {user}
            </a>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#"><i className="fas fa-sign-out-alt"></i></a>
            <div className="dropdown-menu dropdown-menu-right">
              <button className="dropdown-item dropdown-footer" onClick={() => Auth.signOut()}>ログアウト</button>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;

