const Constant = {
  SITE_ID_1: 1,
  SITE_ID_2: 2,
  SITE_ID_3: 3,
  SITE_LIST: [
    { id: 1, name: "沖縄本所" },
    { id: 2, name: "宮古支所" },
    { id: 3, name: "八重山支所" },
  ],
  SITE_NAME_LIST: {
    1: "沖縄本所",
    2: "宮古支所",
    3: "八重山支所",
  },
  CAR_TYPE_1: 1,
  CAR_TYPE_2: 2,
  CAR_TYPE_LIST: {
    1: "普通自動車",
    2: "軽自動車",
  },
  ROUND_1: 1,
  ROUND_2: 2,
  ROUND_3: 3,
  ROUND_4: 4,
  ROUND_5: 5,
  ROUND_LIST: {
    1: "1R",
    2: "2R",
    3: "3R",
    4: "4R",
    5: "5R",
  },
  INSPECTION_TYPE_0: 0,
  INSPECTION_TYPE_1: 1,
  INSPECTION_TYPE_2: 2,
  INSPECTION_TYPE_3: 3,
  INSPECTION_TYPE_4: 4,
  INSPECTION_TYPE_LIST: {
    0: "",
    1: "継続・構造変更",
    2: "中古新規",
    3: "新車新規",
    4: "マルチュウ",
  },
  BUSINESS_FORM_1: 1,
  BUSINESS_FORM_2: 2,
  BUSINESS_FORM_LIST: {
    1: "営業日",
    2: "休日",
  },
};
export default Constant;
