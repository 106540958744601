import ja from "date-fns/locale/ja";
import React from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("ja", ja);

export const DatePicker = (props) => {
  return (
    <ReactDatePicker
      locale={ja}
      dateFormat="yyyy-MM-dd"
      calendarStartDay={1}
      showYearDropdown
      {...props}
    />
  );
};
