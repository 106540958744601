import { API, Auth } from "aws-amplify";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import config from "../config.json";

export default function CourseList(props) {
  const navigate = useNavigate();
  return <CourseListComp {...props} navigate={navigate} />;
}
class CourseListComp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      api_name: config.stage,
      site_id: 0,
      car_type: 1,
      name: "",
      courses: [],
    };
    this.getCourses();
  }

  getCourses = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myinit = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        site_id: this.state.site_id,
        car_type: this.state.car_type,
        name: this.state.name,
      },
    };

    await API.get(this.state.api_name, "/courses", myinit)
      .then((r) => {
        this.setState({
          myinit: myinit,
          courses: r,
        });
      })
      .catch((e) => {
        toast.error("コースデータの取得エラー");
      });
  };

  handleSiteId = (event) => {
    this.setState({
      site_id: event.target.value,
    });
  };

  handleCarType = (event) => {
    this.setState({
      car_type: event.target.value,
    });
  };

  handleName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  searchOnClick = async (event) => {
    this.getCourses();
  };

  render() {
    const courses_html = [];
    const fs = this.state.courses;
    for (let i = 0; i < fs.length; i++) {
      courses_html.push(
        <tr key={i}>
          <td style={{ width: "75px", textAlign: "center" }}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                this.props.navigate("/course_edit/" + fs[i].Course.id);
              }}
            >
              編集
            </button>
          </td>
          <td style={{ width: "40%" }}>{fs[i].Course.name}</td>
          <td>{fs[i].Site.name}</td>
          <td>{fs[i].car_type_name}</td>
        </tr>
      );
    }

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">コースマスター</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body pt-2 pb-2">
                <div className="row">
                  <div className="col-lg-2" style={{ maxWidth: "200px" }}>
                    <label className="small">検査場</label>
                    <div className="form-group mb-0">
                      <select
                        className="form-control select2bs4"
                        defaultValue="0"
                        style={{ width: "100%" }}
                        value={this.state.site_id}
                        onChange={this.handleSiteId}
                      >
                        <option value="0">-</option>
                        <option value="1">沖縄本所</option>
                        <option value="2">宮古支所</option>
                        <option value="3">八重山支所</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3" style={{ maxWidth: "240px" }}>
                    <label className="small">自動車区分</label>
                    <div className="form-group mb-0">
                      <div className="form-group mb-0">
                        <div
                          className="form-check-inline"
                          onChange={this.handleCarType}
                        >
                          <div className="icheck-primary">
                            <input
                              type="radio"
                              className="form-control"
                              id="car_type_normal"
                              name="car_type"
                              defaultChecked
                              value="1"
                            />
                            <label htmlFor="car_type_normal">普通自動車</label>
                          </div>
                          <div className="icheck-primary ml-2">
                            <input
                              type="radio"
                              className="form-control"
                              id="car_type_small"
                              name="car_type"
                              value="2"
                            />
                            <label htmlFor="car_type_small">軽自動車</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: "250px" }}>
                    <label className="small">コース名</label>
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="※部分一致"
                        value={this.state.name}
                        onChange={this.handleName}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer pt-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group clearfix mb-0">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={this.searchOnClick}
                      >
                        検索
                      </button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group clearfix mb-0 text-right">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          this.props.navigate("/course_edit");
                        }}
                      >
                        新規登録
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-primary card-outline">
              <div className="card-body table-responsive">
                <table
                  id="course_datatable"
                  className="table table-sm table-bordered table-hover table-head-fixed text-nowrap"
                >
                  <thead>
                    <tr>
                      <th>編集</th>
                      <th>コース名</th>
                      <th>検査場</th>
                      <th>自動車区分</th>
                    </tr>
                  </thead>
                  <tbody>{courses_html}</tbody>
                </table>
              </div>
            </div>
          </div>
          {/* .container-fluid */}
        </section>
        {/* .content */}
        {/* .content-wrapper */}
      </div>
    );
  }
}
