import { Amplify } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import config from "./config.json";

Amplify.configure({
  aws_cognito_region: config.region,
  aws_user_pools_id: config.user_pools_id,
  aws_user_pools_web_client_id: config.user_pools_web_client_id,
  API: {
    endpoints: [
      {
        name: config.stage,
        //endpoint: "https://" + config.domain + "/" + config.stage,
        endpoint: "https://" + config.domain,
      },
    ],
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
