import React from "react";
import { NavLink } from "react-router-dom";

class Sidebar extends React.Component {
  render() {
    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4 sidebar-no-expand">
        <NavLink to="/" className="brand-link text-center">
          <span className="brand-text font-weight-bold">
            車検予約管理システム
          </span>
        </NavLink>

        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <NavLink to="/reservation" className="nav-link">
                  <i className="nav-icon fas fa-calendar-alt"></i>
                  <p>予約管理</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/reservation_log" className="nav-link">
                  <i className="nav-icon fas fa-history"></i>
                  <p>予約履歴</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/csv_output" className="nav-link">
                  <i className="nav-icon fas fa-download"></i>
                  <p>CSVデータ出力</p>
                </NavLink>
              </li>
              {/*
              <li className="nav-item">
                <NavLink
                  to="/news_list"
                  className="nav-link"
                >
                  <i className="nav-icon fas fa-rss"></i>
                  <p>お知らせ管理</p>
                </NavLink>
              </li>
               */}
              <li className="nav-item">
                <NavLink to="/site_list" className="nav-link">
                  <i className="nav-icon fas fa-warehouse"></i>
                  <p>検査場マスター</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/member_list" className="nav-link">
                  <i className="nav-icon fas fa-users"></i>
                  <p>会員マスター</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/course_list" className="nav-link">
                  <i className="nav-icon fas fa-wrench"></i>
                  <p>コースマスター</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/holiday" className="nav-link">
                  <i className="nav-icon fas fa-calendar-check"></i>
                  <p>休日マスター</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/user_list" className="nav-link">
                  <i className="nav-icon fas fa-user"></i>
                  <p>担当者マスター</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/system_setting" className="nav-link">
                  <i className="nav-icon fas fa-cog"></i>
                  <p>システム設定</p>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
