import { API, Auth } from "aws-amplify";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import config from "../config.json";
//import { TimePicker } from "../components/TimePicker";

export default function SystemSetting(props) {
  const navigate = useNavigate();
  return <SystemSettingComp {...props} navigate={navigate} />;
}

class SystemSettingComp extends React.Component {
  constructor(props, params) {
    super(props);
    this.state = {
      api_name: config.stage,
      id: 1,
      system_setting: {},
      request: {},
    };

    this.readSystemSetting();
  }

  readSystemSetting = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        timestamp: new Date().getTime(),
      },
    };

    await API.get(
      this.state.api_name,
      //"/system_settings/" + this.state.id,
      "/system_settings",
      request
    )
      .then((r) => {
        console.log(r);
        this.setState({
          system_setting: r,
        });
      })
      .catch((e) => {
        toast.error("システム設定データの取得エラー");
      });
  };

  updateSystemSetting = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      body: this.state.system_setting,
    };

    await API.put(
      this.state.api_name,
      //"/system_settings/" + this.state.id,
      "/system_settings",
      request
    )
      .then((r) => {
        console.log(r);
        this.readSystemSetting();
        toast.success("システム設定データを登録しました");
      })
      .catch((e) => {
        toast.error("システム設定データの登録エラー");
      });
  };

  updateSystemSettingOnClick = async (event) => {
    if (
      this.state.system_setting.reservation_disable_minute_min >
      this.state.system_setting.reservation_disable_minute_max
    ) {
      alert(
        "キャンセル予約不可時間最小は、キャンセル予約不可時間最大以下で入力してください。"
      );
      return false;
    }
    this.updateSystemSetting();
  };

  render() {
    return (
      <div className="content-wrapper">
        <Toaster />
        {/* Content Wrapper. Contains page content */}
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">システム設定</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body">
                <div className="row table-responsive">
                  <table className="table table-sm table-bordered text-nowrap">
                    <thead>
                      <tr>
                        <th
                          className="table-secondary"
                          style={{ width: "15%" }}
                        >
                          種別
                        </th>
                        <th
                          className="table-secondary"
                          style={{ width: "20%" }}
                        >
                          予約開始
                        </th>
                        <th
                          className="table-secondary"
                          style={{ width: "20%" }}
                        >
                          予約終了
                        </th>
                        <th
                          className="table-secondary"
                          style={{ width: "20%" }}
                        >
                          キャンセル終了
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className="table-secondary">普通（継続）</th>
                        <td>
                          <div className="form-group">
                            <div className="form-group mb-0 form-inline">
                              <input
                                type="text"
                                className="form-control"
                                maxLength="1"
                                value={
                                  this.state.system_setting.normal_rsv_start_day
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_rsv_start_day = event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <span className="ml-2">日前</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0 form-inline">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="1"
                              value={
                                this.state.system_setting.normal_rsv_end_day
                              }
                              onChange={(event) => {
                                const ss = this.state.system_setting;
                                ss.normal_rsv_end_day = event.target.value;
                                this.setState({
                                  system_setting: ss,
                                });
                              }}
                            />
                            <span className="ml-2">日前</span>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0 form-inline">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="1"
                              value={
                                this.state.system_setting.normal_cancel_end_day
                              }
                              onChange={(event) => {
                                const ss = this.state.system_setting;
                                ss.normal_cancel_end_day = event.target.value;
                                this.setState({
                                  system_setting: ss,
                                });
                              }}
                            />
                            <span className="ml-2">日前</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">普通（新車）</th>
                        <td>
                          <div className="form-group">
                            <div className="form-group mb-0 form-inline">
                              <input
                                type="text"
                                className="form-control"
                                maxLength="1"
                                value={
                                  this.state.system_setting
                                    .normal_new_rsv_start_day
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_new_rsv_start_day =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <span className="ml-2">日前</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0 form-inline">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="1"
                              value={
                                this.state.system_setting.normal_new_rsv_end_day
                              }
                              onChange={(event) => {
                                const ss = this.state.system_setting;
                                ss.normal_new_rsv_end_day = event.target.value;
                                this.setState({
                                  system_setting: ss,
                                });
                              }}
                            />
                            <span className="ml-2">日前</span>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0 form-inline">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="1"
                              value={
                                this.state.system_setting
                                  .normal_new_cancel_end_day
                              }
                              onChange={(event) => {
                                const ss = this.state.system_setting;
                                ss.normal_new_cancel_end_day =
                                  event.target.value;
                                this.setState({
                                  system_setting: ss,
                                });
                              }}
                            />
                            <span className="ml-2">日前</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">普通（マルチュウ）</th>
                        <td>
                          <div className="form-group">
                            <div className="form-group mb-0 form-inline">
                              <input
                                type="text"
                                className="form-control"
                                maxLength="1"
                                value={
                                  this.state.system_setting
                                    .normal_maru_rsv_start_day
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_maru_rsv_start_day =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <span className="ml-2">日前</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0 form-inline">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="1"
                              value={
                                this.state.system_setting
                                  .normal_maru_rsv_end_day
                              }
                              onChange={(event) => {
                                const ss = this.state.system_setting;
                                ss.normal_maru_rsv_end_day = event.target.value;
                                this.setState({
                                  system_setting: ss,
                                });
                              }}
                            />
                            <span className="ml-2">日前</span>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0 form-inline">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="1"
                              value={
                                this.state.system_setting
                                  .normal_maru_cancel_end_day
                              }
                              onChange={(event) => {
                                const ss = this.state.system_setting;
                                ss.normal_maru_cancel_end_day =
                                  event.target.value;
                                this.setState({
                                  system_setting: ss,
                                });
                              }}
                            />
                            <span className="ml-2">日前</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">軽</th>
                        <td>
                          <div className="form-group">
                            <div className="form-group mb-0 form-inline">
                              <input
                                type="text"
                                className="form-control"
                                maxLength="1"
                                value={
                                  this.state.system_setting.light_rsv_start_day
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.light_rsv_start_day = event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <span className="ml-2">日前</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0 form-inline">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="1"
                              value={
                                this.state.system_setting.light_rsv_end_day
                              }
                              onChange={(event) => {
                                const ss = this.state.system_setting;
                                ss.light_rsv_end_day = event.target.value;
                                this.setState({
                                  system_setting: ss,
                                });
                              }}
                            />
                            <span className="ml-2">日前</span>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0 form-inline">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="1"
                              value={
                                this.state.system_setting.light_cancel_end_day
                              }
                              onChange={(event) => {
                                const ss = this.state.system_setting;
                                ss.light_cancel_end_day = event.target.value;
                                this.setState({
                                  system_setting: ss,
                                });
                              }}
                            />
                            <span className="ml-2">日前</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row table-responsive">
                  <table className="table table-sm table-bordered text-nowrap">
                    <thead>
                      <tr>
                        <th
                          className="table-secondary"
                          style={{ width: "15%" }}
                        >
                          種別
                        </th>
                        <th
                          className="table-secondary"
                          style={{ width: "15%" }}
                        >
                          対象
                        </th>
                        <th
                          className="table-secondary"
                          style={{ width: "10%" }}
                        >
                          予約受付
                          <br />
                          締切時間
                        </th>
                        <th
                          className="table-secondary"
                          style={{ width: "10%" }}
                        >
                          予約キャンセル
                          <br />
                          締切時間
                        </th>
                        <th
                          className="table-secondary"
                          style={{ width: "10%" }}
                        >
                          予約ペナルティ
                          <br />
                          開始時間
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th rowSpan="5" className="table-secondary">
                          普通（継続）
                        </th>
                        <th className="table-secondary">1ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="normal_round_1_rsv_end_time"
                              data-target-input="nearest"
                            >
                              {/*
                              <TimePicker
                                className="form-control"
                                //selected={this.state.system_setting.normal_round_1_rsv_end_time}
                                selected={new Date()}
                                onChange={(time) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_round_1_rsv_end_time = time;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                               */}
                              <input
                                type="text"
                                className="form-control"
                                data-target="#normal_round_1_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .normal_round_1_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_round_1_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#normal_round_1_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_round_1_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_round_1_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_round_1_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_round_1_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_round_1_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_round_1_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_round_1_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_round_1_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_round_1_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_round_1_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">2ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="normal_round_2_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#normal_round_2_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .normal_round_2_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_round_2_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#normal_round_2_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_round_2_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_round_2_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_round_2_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_round_2_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_round_2_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_round_2_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_round_2_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_round_2_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_round_2_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_round_2_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">3ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="normal_round_3_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#normal_round_3_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .normal_round_3_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_round_3_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#normal_round_3_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_round_3_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_round_3_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_round_3_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_round_3_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_round_3_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_round_3_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_round_3_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_round_3_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_round_3_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_round_3_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">4ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="normal_round_4_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#normal_round_4_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .normal_round_4_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_round_4_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#normal_round_4_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_round_4_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_round_4_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_round_4_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_round_4_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_round_4_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_round_4_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control datetimepicker-input"
                                  data-target="#normal_round_4_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_round_4_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_round_4_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_round_4_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">5ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="normal_round_5_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#normal_round_5_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .normal_round_5_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_round_5_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#normal_round_5_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_round_5_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_round_5_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_round_5_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_round_5_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_round_5_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_round_5_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_round_5_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_round_5_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_round_5_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_round_5_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th rowSpan="5" className="table-secondary">
                          普通（新車）
                        </th>
                        <th className="table-secondary">1ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="normal_new_round_1_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#normal_new_round_1_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .normal_new_round_1_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_new_round_1_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#normal_new_round_1_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_new_round_1_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_new_round_1_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_new_round_1_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_new_round_1_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_new_round_1_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_new_round_1_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_new_round_1_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_new_round_1_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_new_round_1_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_new_round_1_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">2ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="normal_new_round_2_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#normal_new_round_2_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .normal_new_round_2_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_new_round_2_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#normal_new_round_2_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_new_round_2_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_new_round_2_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_new_round_2_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_new_round_2_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_new_round_2_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_new_round_2_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_new_round_2_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_new_round_2_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_new_round_2_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_new_round_2_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">3ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="normal_new_round_3_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#normal_new_round_3_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .normal_new_round_3_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_new_round_3_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#normal_new_round_3_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_new_round_3_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_new_round_3_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_new_round_3_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_new_round_3_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_new_round_3_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_new_round_3_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_new_round_3_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_new_round_3_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_new_round_3_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_new_round_3_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">4ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="normal_new_round_4_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#normal_new_round_4_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .normal_new_round_4_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_new_round_4_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#normal_new_round_4_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_new_round_4_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_new_round_4_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_new_round_4_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_new_round_4_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_new_round_4_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_new_round_4_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_new_round_4_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_new_round_4_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_new_round_4_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_new_round_4_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">5ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="normal_new_round_5_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#normal_new_round_5_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .normal_new_round_5_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_new_round_5_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#normal_new_round_5_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_new_round_5_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_new_round_5_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_new_round_5_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_new_round_5_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_new_round_5_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_new_round_5_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_new_round_5_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_new_round_5_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_new_round_5_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_new_round_5_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th rowSpan="1" className="table-secondary">
                          普通（マルチュウ）
                        </th>
                        <th className="table-secondary">-</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="normal_maru_round_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#normal_maru_round_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .normal_maru_round_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.normal_maru_round_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#normal_maru_round_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_maru_round_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_maru_round_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_maru_round_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_maru_round_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_maru_round_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="normal_maru_round_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#normal_maru_round_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .normal_maru_round_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.normal_maru_round_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#normal_maru_round_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th rowSpan="5" className="table-secondary">
                          軽
                        </th>
                        <th className="table-secondary">1ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="light_round_1_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#light_round_1_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .light_round_1_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.light_round_1_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#light_round_1_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="light_round_1_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#light_round_1_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .light_round_1_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.light_round_1_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#light_round_1_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="light_round_1_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#light_round_1_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .light_round_1_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.light_round_1_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#light_round_1_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">2ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="light_round_2_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#light_round_2_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .light_round_2_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.light_round_2_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#light_round_2_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="light_round_2_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#light_round_2_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .light_round_2_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.light_round_2_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#light_round_2_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="light_round_2_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#light_round_2_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .light_round_2_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.light_round_2_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#light_round_2_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">3ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="light_round_3_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#light_round_3_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .light_round_3_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.light_round_3_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#light_round_3_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="light_round_3_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#light_round_3_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .light_round_3_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.light_round_3_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#light_round_3_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="light_round_3_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#light_round_3_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .light_round_3_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.light_round_3_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#light_round_3_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">4ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="light_round_4_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#light_round_4_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .light_round_4_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.light_round_4_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#light_round_4_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="light_round_4_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#light_round_4_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .light_round_4_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.light_round_4_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#light_round_4_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="light_round_4_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#light_round_4_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .light_round_4_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.light_round_4_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#light_round_4_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">5ラウンド</th>
                        <td>
                          <div className="form-group">
                            <div
                              className="input-group date setting_time"
                              id="light_round_5_rsv_end_time"
                              data-target-input="nearest"
                            >
                              <input
                                type="text"
                                className="form-control"
                                data-target="#light_round_5_rsv_end_time"
                                maxLength="5"
                                value={
                                  this.state.system_setting
                                    .light_round_5_rsv_end_time
                                }
                                onChange={(event) => {
                                  const ss = this.state.system_setting;
                                  ss.light_round_5_rsv_end_time =
                                    event.target.value;
                                  this.setState({
                                    system_setting: ss,
                                  });
                                }}
                              />
                              <div
                                className="input-group-append"
                                data-target="#light_round_5_rsv_end_time"
                                data-toggle="datetimepicker"
                              >
                                <div className="input-group-text">
                                  <i className="fa fa-clock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="light_round_5_cancel_end_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#light_round_5_cancel_end_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .light_round_5_cancel_end_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.light_round_5_cancel_end_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#light_round_5_cancel_end_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <div className="form-group">
                              <div
                                className="input-group date setting_time"
                                id="light_round_5_penalty_start_time"
                                data-target-input="nearest"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  data-target="#light_round_5_penalty_start_time"
                                  maxLength="5"
                                  value={
                                    this.state.system_setting
                                      .light_round_5_penalty_start_time
                                  }
                                  onChange={(event) => {
                                    const ss = this.state.system_setting;
                                    ss.light_round_5_penalty_start_time =
                                      event.target.value;
                                    this.setState({
                                      system_setting: ss,
                                    });
                                  }}
                                />
                                <div
                                  className="input-group-append"
                                  data-target="#light_round_5_penalty_start_time"
                                  data-toggle="datetimepicker"
                                >
                                  <div className="input-group-text">
                                    <i className="fa fa-clock"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-2">
                    <label htmlFor="">キャンセル予約不可時間最小（分）</label>
                  </div>
                  <div className="col-lg-1">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        maxLength="3"
                        value={
                          this.state.system_setting
                            .reservation_disable_minute_min
                        }
                        onChange={(event) => {
                          const ss = this.state.system_setting;
                          ss.reservation_disable_minute_min =
                            event.target.value;
                          this.setState({
                            system_setting: ss,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <label htmlFor="">キャンセル予約不可時間最大（分）</label>
                  </div>
                  <div className="col-lg-1">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        maxLength="3"
                        value={
                          this.state.system_setting
                            .reservation_disable_minute_max
                        }
                        onChange={(event) => {
                          const ss = this.state.system_setting;
                          ss.reservation_disable_minute_max =
                            event.target.value;
                          this.setState({
                            system_setting: ss,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2">
                    <label htmlFor="">制限台数</label>
                  </div>
                  <div className="col-lg-1">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="limit_car_num"
                        maxLength="2"
                        value={this.state.system_setting.limit_car_num}
                        onChange={(event) => {
                          const ss = this.state.system_setting;
                          ss.limit_car_num = event.target.value;
                          this.setState({
                            system_setting: ss,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <label htmlFor="">制限日数</label>
                  </div>
                  <div className="col-lg-1">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="limit_days"
                        maxLength="2"
                        value={this.state.system_setting.limit_days}
                        onChange={(event) => {
                          const ss = this.state.system_setting;
                          ss.limit_days = event.target.value;
                          this.setState({
                            system_setting: ss,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/*
                <div className="row">
                  <div className="col-lg-1">
                    <label htmlFor="">最終更新日時</label>
                  </div>
                  <div className="col-lg-2">2022/01/10 10:10:10</div>
                  <div className="col-lg-1">
                    <label htmlFor="">最終更新者</label>
                  </div>
                  <div className="col-lg-2">担当者A</div>
                </div>
                */}
              </div>
              <div className="card-footer pt-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group clearfix mb-0">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.updateSystemSettingOnClick}
                      >
                        登録
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}

        {/* /.content-wrapper */}
      </div>
    );
  }
}
