import { API, Auth } from "aws-amplify";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import config from "../config.json";
import constant from "../Constant.js";
import { DatePicker } from "./DatePicker";

export default function MemberEdit(props) {
  const params = useParams();
  const navigate = useNavigate();

  return <MemberEditComp {...props} navigate={navigate} params={params} />;
}

class MemberEditComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: config.stage,
      loading: false,
      site_list: constant.SITE_LIST,
      user: null,
      myinit: {},
      memberId: props.params.memberId,
      member: {},
      limit_start_date: null,
      limit_end_date: null,
      site_name: null,
      updated_user_name: null,
    };
    //this.initialize();
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = await user.signInUserSession.idToken.jwtToken;
    const myinit = await {
      headers: {
        Authorization: token,
      },
    };

    await this.setState({
      token: token,
      myinit: myinit,
    });

    if (this.state.memberId) {
      this.getMember();
    }
  };

  getFormatDate(date) {
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  getMember = async () => {
    this.setState({ loading: true });
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myinit = {
      headers: {
        Authorization: token,
      },
    };

    await API.get(
      this.state.api_name,
      "/members/" + this.state.memberId,
      //this.state.myinit
      myinit
    )
      .then((r) => {
        this.setState({
          member: r.Member,
          site_name: r.site_name,
          limit_start_date: r.Member.limit_start_date
            ? new Date(r.Member.limit_start_date)
            : null,
          limit_end_date: r.Member.limit_end_date
            ? new Date(r.Member.limit_end_date)
            : null,
          updated_user_name: r.updated_user_name,
        });
      })
      .catch((e) => {
        toast.error("会員データの取得エラー");
      });

    this.setState({ loading: false });
  };

  //update or create
  updateMember = async () => {
    if (!this.state.member.auth_no) {
      alert("認証番号を入力してください。");
      return;
    }
    if (this.state.member.auth_no.length != 4) {
      alert("認証番号は4桁で入力してください。");
      return;
    }
    if (!this.state.member.site_id) {
      alert("検査場を選択してください。");
      return;
    }
    if (!this.state.member.name) {
      alert("事業者名を入力してください。");
      return;
    }

    this.setState({ loading: true });
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myinit = {
      //headers: this.state.myinit.headers,
      headers: {
        Authorization: token,
      },
      body: this.state.member,
    };

    if (this.state.memberId) {
      //update
      await API.put(
        this.state.api_name,
        "/members/" + this.state.memberId,
        myinit
      )
        .then((r) => {
          this.setState({
            member: r.Member,
          });
          toast.success("会員データを登録しました");
        })
        .catch((e) => {
          toast.error("会員データの登録エラー");
        });
    } else {
      //create
      await API.post(this.state.api_name, "/members", myinit)
        .then((r) => {
          this.setState({
            member: r.Member,
          });
          toast.success("会員データを登録しました");
          window.location.href = "/member_edit/" + r.Member.id;
        })
        .catch((e) => {
          toast.error("会員データの登録エラー");
        });
    }

    this.setState({ loading: false });
  };

  deleteMember = async () => {
    this.setState({ loading: true });
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myinit = {
      //headers: this.state.myinit.headers,
      headers: {
        Authorization: token,
      },
    };

    await API.del(
      this.state.api_name,
      "/members/" + this.state.memberId,
      //this.state.myinit
      myinit
    )
      .then((r) => {
        //deleted
        toast.success("会員データを削除しました");
      })
      .catch((e) => {
        toast.error("会員データの削除エラー");
      });

    this.setState({ loading: false });
  };

  resetMemberPassword = async () => {
    this.setState({ loading: true });
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myinit = {
      //headers: this.state.myinit.headers,
      headers: {
        Authorization: token,
      },
    };

    await API.post(
      this.state.api_name,
      "/members/" + this.state.memberId + "/password_reset",
      //this.state.myinit
      myinit
    )
      .then((r) => {
        this.setState({
          member: r.Member,
        });
        toast.success("会員データのパスワードを初期化しました");
      })
      .catch((e) => {
        toast.error("会員データのパスワード初期化エラー");
      });

    this.setState({ loading: false });
  };

  handleAuthNo = (event) => {
    const m = this.state.member;
    m.auth_no = event.target.value;
    this.setState({ member: m });
  };

  handleSiteId = (event) => {
    const m = this.state.member;
    m.site_id = event.target.value;
    this.setState({ member: m });
  };

  handleName = (event) => {
    const m = this.state.member;
    m.name = event.target.value;
    this.setState({ member: m });
  };

  handleStatus = (event) => {
    const m = this.state.member;
    m.status = event.target.value;
    this.setState({ member: m });
  };

  handleTel = (event) => {
    const m = this.state.member;
    m.tel = event.target.value;
    this.setState({ member: m });
  };

  handleFax = (event) => {
    const m = this.state.member;
    m.fax = event.target.value;
    this.setState({ member: m });
  };

  handleZipCode = (event) => {
    const m = this.state.member;
    m.zip_code = event.target.value;
    this.setState({ member: m });
  };

  handleAddress1 = (event) => {
    const m = this.state.member;
    m.address_1 = event.target.value;
    this.setState({ member: m });
  };

  handleAddress2 = (event) => {
    const m = this.state.member;
    m.address_2 = event.target.value;
    this.setState({ member: m });
  };

  handleEmail = (event) => {
    const m = this.state.member;
    m.email = event.target.value;
    this.setState({ member: m });
  };

  handleLimitStartDate = (event) => {
    this.state.limit_start_date = event;
    const m = this.state.member;
    m.limit_start_date = this.state.limit_start_date
      ? this.getFormatDate(this.state.limit_start_date)
      : null;
    this.setState({ member: m });
  };

  handleLimitEndDate = (event) => {
    this.state.limit_end_date = event;
    const m = this.state.member;
    m.limit_end_date = this.state.limit_end_date
      ? this.getFormatDate(this.state.limit_end_date)
      : null;
    this.setState({ member: m });
  };

  handleLimitCarNum = (event) => {
    const m = this.state.member;
    m.limit_car_num = event.target.value;
    this.setState({ member: m });
  };

  render() {
    const m = this.state.member;

    return (
      <div className="content-wrapper">
        <Toaster />
        {/* Content Wrapper. Contains page content */}
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">会員マスター</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={this.state.loading}
                />
                <div className="row">
                  <div className="col-lg-1">
                    <label htmlFor="auth_no">認証番号</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <input
                        type="text"
                        inputMode="numeric"
                        pattern="\d{4}"
                        className="form-control"
                        id="auth_no"
                        placeholder="9999"
                        maxLength="4"
                        defaultValue=""
                        value={m.auth_no}
                        onChange={this.handleAuthNo}
                      />
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label htmlFor="site_id">検査場</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <select
                        className="form-control select2bs4"
                        style={{ width: "100%" }}
                        defaultValue=""
                        value={this.state.member.site_id}
                        onChange={this.handleSiteId}
                      >
                        <option value="">-</option>
                        {this.state.site_list.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label htmlFor="name">事業者名</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder=""
                        defaultValue=""
                        value={m.name}
                        onChange={this.handleName}
                      />
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label htmlFor="status">ステータス</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <select
                        className="form-control select2bs4"
                        style={{ width: "100%" }}
                        defaultValue="-"
                        value={m.status}
                        onChange={this.handleStatus}
                      >
                        <option value="1">有効</option>
                        <option value="0">無効</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-1">
                    <label htmlFor="tel">TEL</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="tel"
                        placeholder=""
                        maxLength="15"
                        defaultValue=""
                        value={m.tel}
                        onChange={this.handleTel}
                      />
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label htmlFor="fax">FAX</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="fax"
                        placeholder=""
                        maxLength="15"
                        defaultValue=""
                        value={m.fax}
                        onChange={this.handleFax}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-1">
                    <label htmlFor="address1">郵便番号</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="address1"
                        placeholder=""
                        maxLength="8"
                        defaultValue=""
                        value={m.zip_code}
                        onChange={this.handleZipCode}
                      />
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label htmlFor="address1">住所1</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="address1"
                        placeholder=""
                        maxLength="50"
                        defaultValue=""
                        value={m.address_1}
                        onChange={this.handleAddress1}
                      />
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label htmlFor="address2">住所2</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="address2"
                        placeholder=""
                        maxLength="50"
                        defaultValue=""
                        value={m.address_2}
                        onChange={this.handleAddress2}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-1">
                    <label htmlFor="email">メールアドレス</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder=""
                        maxLength="50"
                        defaultValue=""
                        value={m.email}
                        onChange={this.handleEmail}
                      />
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label htmlFor="initial_password">初期パスワード</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">{m.initial_password}</div>
                  </div>
                  <div className="col-lg-1">
                    <label htmlFor="">見込予約回数</label>
                  </div>
                  <div className="col-lg-2">{m.penalty_prospect_count}</div>
                  <div className="col-lg-1">
                    <label htmlFor="">ボイコット回数</label>
                  </div>
                  <div className="col-lg-2">{m.penalty_boycott_count}</div>
                </div>
                <div className="row">
                  <div className="col-lg-1">
                    <label htmlFor="">制限開始日</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <div
                        className="input-group date limit_date"
                        id="limit_start_date"
                        data-target-input="nearest"
                      >
                        <DatePicker
                          className="form-control"
                          selected={this.state.limit_start_date}
                          onChange={this.handleLimitStartDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label htmlFor="">制限終了日</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <div
                        className="input-group date limit_date"
                        id="limit_end_date"
                        data-target-input="nearest"
                      >
                        <DatePicker
                          className="form-control"
                          selected={this.state.limit_end_date}
                          onChange={this.handleLimitEndDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label htmlFor="">制限台数</label>
                  </div>
                  <div className="col-lg-2">
                    <input
                      type="text"
                      className="form-control"
                      id="limit_car_num"
                      placeholder=""
                      maxLength="2"
                      defaultValue=""
                      value={m.limit_car_num}
                      onChange={this.handleLimitCarNum}
                    />
                  </div>
                </div>
                {/*
                <div className="row">
                  <div className="col-lg-1">
                    <label htmlFor="">最終更新日時</label>
                  </div>
                  <div className="col-lg-2">{m.updated_at}</div>
                  <div className="col-lg-1">
                    <label htmlFor="">最終更新者</label>
                  </div>
                  <div className="col-lg-2">{this.state.updated_user_name}</div>
                </div>
                */}
              </div>
              <div className="card-footer pt-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group clearfix mb-0">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.updateMember}
                      >
                        登録
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          this.props.navigate("/member_list");
                        }}
                      >
                        戻る
                      </button>
                    </div>
                  </div>
                  <div className="col-6 text-right">
                    <div className="form-group clearfix mb-0">
                      {this.state.memberId ? (
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={this.resetMemberPassword}
                        >
                          パスワード初期化
                        </button>
                      ) : (
                        <></>
                      )}
                      &nbsp;
                      {/*<button type="button" className="btn btn-danger" onClick={ this.deleteMember }>削除</button>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
        {/* /.content-wrapper */}
      </div>
    );
  }
}
