import { API, Auth } from "aws-amplify";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "../components/DatePicker";
import config from "../config.json";

export default function CsvOutput(props) {
  const navigate = useNavigate();
  return <CsvOutputComp {...props} navigate={navigate} />;
}

class CsvOutputComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: config.stage,
      reservation_date_start: new Date(),
      reservation_date_end: new Date(),
      site_id: 1,
      car_type: 0,
      round: 0,
      auth_no: "",
      request: {},
      //reservations: [],
    };
  }

  getFormatDate(date) {
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  getCsv = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        reservation_date_start: this.getFormatDate(
          this.state.reservation_date_start
        ),
        reservation_date_end: this.getFormatDate(
          this.state.reservation_date_end
        ),
        site_id: this.state.site_id,
        car_type: this.state.car_type,
        round: this.state.round,
        auth_no: this.state.auth_no,
        timestamp: new Date().getTime(),
      },
    };

    await API.get(this.state.api_name, "/outputs/csv", request)
      .then((r) => {
        if (r) {
          const a = document.createElement("a");
          a.href = r;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch((e) => {
        alert("データ取得エラー"); // TODO
      });
  };

  handleReservationDateStart = (event) => {
    this.setState({
      reservation_date_start: event.target.value,
    });
  };

  handleReservationDateEnd = (event) => {
    this.setState({
      reservation_date_end: event.target.value,
    });
  };

  handleSiteId = (event) => {
    this.setState({
      site_id: event.target.value,
    });
  };

  handleCarType = (event) => {
    this.setState({
      car_type: event.target.value,
    });
  };

  handleRound = (event) => {
    this.setState({
      round: event.target.value,
    });
  };

  handleAuthNo = (event) => {
    this.setState({
      auth_no: event.target.value,
    });
  };

  csvOnClick = async (event) => {
    this.getCsv();
  };

  render() {
    console.log("render");

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">CSVデータ出力</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body pt-2 pb-2">
                <div className="row">
                  <div className="col-lg-2" style={{ maxWidth: "160px" }}>
                    <label className="small">予約日</label>
                    <div className="form-group mb-0">
                      <div
                        className="input-group date reservation_date_start"
                        id="reservation_date_start"
                        data-target-input="nearest"
                      >
                        <DatePicker
                          className="form-control"
                          selected={this.state.reservation_date_start}
                          onChange={(date) =>
                            this.setState({ reservation_date_start: date })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1" style={{ maxWidth: "15px" }}>
                    <label className="small">　</label>
                    <div className="form-group mb-0">
                      <div className="form-text">-</div>
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: "160px" }}>
                    <label className="small">　</label>
                    <div className="form-group mb-0">
                      <div
                        className="input-group date reservation_date_end"
                        id="reservation_date_end"
                        data-target-input="nearest"
                      >
                        <DatePicker
                          className="form-control"
                          selected={this.state.reservation_date_end}
                          onChange={(date) =>
                            this.setState({ reservation_date_end: date })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: "200px" }}>
                    <label className="small">検査場</label>
                    <div className="form-group mb-0">
                      <select
                        className="form-control select2bs4"
                        defaultValue="-"
                        style={{ width: "100%" }}
                        value={this.state.site_id}
                        onChange={this.handleSiteId}
                      >
                        <option value="1">沖縄本所</option>
                        <option value="2">宮古支所</option>
                        <option value="3">八重山支所</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4" style={{ maxWidth: "200px" }}>
                    <label className="small">自動車区分</label>
                    <div className="form-group mb-0">
                      <select
                        className="form-control select2bs4"
                        defaultValue="0"
                        style={{ width: "100%" }}
                        value={this.state.car_type}
                        onChange={this.handleCarType}
                      >
                        <option value="0">-</option>
                        <option value="1">普通自動車</option>
                        <option value="2">軽自動車</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: "150px" }}>
                    <label className="small">ラウンド</label>
                    <div className="form-group mb-0">
                      <select
                        className="form-control select2bs4"
                        defaultValue="0"
                        style={{ width: "100%" }}
                        value={this.state.round}
                        onChange={this.handleRound}
                      >
                        <option value="0">-</option>
                        <option value="1">1ラウンド</option>
                        <option value="2">2ラウンド</option>
                        <option value="3">3ラウンド</option>
                        <option value="4">4ラウンド</option>
                        <option value="5">5ラウンド</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: "200px" }}>
                    <label className="small">認証番号</label>
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue=""
                        value={this.state.auth_no}
                        placeholder="※完全一致"
                        onChange={this.handleAuthNo}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer pt-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group clearfix mb-0">
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={this.csvOnClick}
                      >
                        CSV出力
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
        {/* /.content-wrapper */}
      </div>
    );
  }
}
