import { API, Auth } from 'aws-amplify'
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NewsList(props) {
  const navigate = useNavigate();
  return (
    <NewsListComp {...props} navigate={ navigate } />
  );
}

class NewsListComp extends React.Component {
  render() {


    return (
      <div className="content-wrapper">
      {/* Content Wrapper. Contains page content */}
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">お知らせ管理</h3>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body pt-2 pb-2">
                <div className="row">
                  <div className="col-lg-2" style={{ maxWidth: '250px' }}>
                    <label className="small">タイトル</label>
                    <div className="form-group mb-0">
                      <input type="text" className="form-control" id="title" placeholder="※部分一致" />
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: '250px' }}>
                    <label className="small">本文</label>
                    <div className="form-group mb-0">
                      <input type="text" className="form-control" id="content" placeholder="※部分一致" />
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: '160px' }}>
                    <label className="small">公開日</label>
                    <div className="form-group mb-0">
                      <div className="input-group date publish_date" id="publish_date_start" data-target-input="nearest">
                        <input type="text" className="form-control datetimepicker-input" data-target="#publish_date_start"
                          defaultValue="2022/01/01" />
                        <div className="input-group-append" data-target="#publish_date_start" data-toggle="datetimepicker">
                          <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1" style={{ maxWidth: '15px' }}>
                    <label className="small">　</label>
                    <div className="form-group mb-0">
                      <div className="form-text">-</div>
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: '160px' }}>
                    <label className="small">　</label>
                    <div className="form-group mb-0">
                      <div className="input-group date publish_date" id="publish_date_end" data-target-input="nearest">
                        <input type="text" className="form-control datetimepicker-input" data-target="#publish_date_end"
                          defaultValue="2022/01/31" />
                        <div className="input-group-append" data-target="#publish_date_end" data-toggle="datetimepicker">
                          <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer pt-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group clearfix mb-0">
                      <button type="button" className="btn btn-success">検索</button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group clearfix mb-0 text-right">
                      <button type="button" className="btn btn-primary" onClick={ () => { this.props.navigate('/news_edit'); } }>新規登録</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-primary card-outline">
              <div className="card-body table-responsive">
                <table id="news_datatable" className="table table-sm table-bordered table-hover table-head-fixed text-nowrap">
                  <thead>
                    <tr>
                      <th>編集</th>
                      <th>タイトル</th>
                      <th>本文</th>
                      <th>公開日</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><button type="button" className="btn btn-sm btn-primary"
                          onClick={ () => { this.props.navigate('/news_edit'); } }>編集</button>
                      </td>
                      <td>タイトル1</td>
                      <td>本文1</td>
                      <td>2022/01/01</td>
                    </tr>
                    <tr>
                      <td><button type="button" className="btn btn-sm btn-primary"
                          onClick={ () => { this.props.navigate('/news_edit'); } }>編集</button>
                      </td>
                      <td>タイトル2</td>
                      <td>本文2</td>
                      <td>2022/01/10</td>
                    </tr>
                    <tr>
                      <td><button type="button" className="btn btn-sm btn-primary"
                          onClick={ () => { this.props.navigate('/news_edit'); } }>編集</button>
                      </td>
                      <td>タイトル3</td>
                      <td>本文3</td>
                      <td>2022/01/20</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
      {/* /.content-wrapper */}
      </div>
    );
  }
}

