import { API, Auth } from "aws-amplify";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import config from "../config.json";

export default function UserEdit(props) {
  const params = useParams();
  const navigate = useNavigate();

  return <UserEditComp {...props} navigate={navigate} params={params} />;
}

class UserEditComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: config.stage,
      loading: false,
      user: {
        status: "1",
      },
      userId: props.params.userId,
      myinit: {},
    };
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = await user.signInUserSession.idToken.jwtToken;
    const myinit = await {
      headers: {
        Authorization: token,
      },
    };

    await this.setState({
      token: token,
      myinit: myinit,
    });

    if (this.state.userId) {
      this.getUser();
    }
  };

  getUser = async () => {
    this.setState({ loading: true });

    await API.get(
      this.state.api_name,
      "/users/" + this.state.userId,
      this.state.myinit
    )
      .then((r) => {
        this.setState({
          user: r,
        });
      })
      .catch((e) => {
        toast.error("担当者データの取得エラー");
      });

    this.setState({ loading: false });
  };

  //update or create
  updateUser = async () => {
    if (!this.state.user.name) {
      alert("担当者名を入力してください。");
      return;
    }
    if (!this.state.user.email) {
      alert("メールアドレスを入力してください。");
      return;
    }

    this.setState({ loading: true });
    const myinit = {
      headers: this.state.myinit.headers,
      body: this.state.user,
    };

    if (this.state.userId) {
      //update
      await API.put(this.state.api_name, "/users/" + this.state.userId, myinit)
        .then((r) => {
          this.setState({
            user: r,
          });
          toast.success("担当者データを登録しました");
        })
        .catch((e) => {
          toast.error("担当者データの登録エラー");
        });
    } else {
      //create
      await API.post(this.state.api_name, "/users", myinit)
        .then((r) => {
          this.setState({
            user: r,
          });
          toast.success("担当者データを登録しました");
          window.location.href = "/user_edit/" + r.id;
        })
        .catch((e) => {
          toast.error("担当者データの登録エラー");
        });
    }

    this.setState({ loading: false });
  };

  resetUserPassword = async () => {
    this.setState({ loading: true });

    await API.post(
      this.state.api_name,
      "/users/" + this.state.userId + "/password_reset",
      this.state.myinit
    )
      .then((r) => {
        this.setState({
          user: r,
        });
        toast.success("担当者データのパスワードを初期化しました");
      })
      .catch((e) => {
        toast.error("担当者データのパスワード初期化エラー");
      });

    this.setState({ loading: false });
  };

  handleEmail = (event) => {
    const u = this.state.user;
    u.email = event.target.value;
    this.setState({ user: u });
  };

  handleName = (event) => {
    const u = this.state.user;
    u.name = event.target.value;
    this.setState({ user: u });
  };

  handleKana = (event) => {
    const u = this.state.user;
    u.kana = event.target.value;
    this.setState({ user: u });
  };

  handleStatus = (event) => {
    const u = this.state.user;
    u.status = event.target.value;
    this.setState({ user: u });
  };

  render() {
    const u = this.state.user;

    return (
      <div className="content-wrapper">
        <Toaster />
        {/* Content Wrapper. Contains page content */}
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">担当者マスター</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={this.state.loading}
                />
                <div className="row">
                  <div className="col-lg-1">
                    <label htmlFor="name">担当者名</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder=""
                        defaultValue=""
                        value={u.name}
                        onChange={this.handleName}
                      />
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label htmlFor="kana">担当者名カナ</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="kana"
                        placeholder=""
                        defaultValue=""
                        value={u.kana}
                        onChange={this.handleKana}
                      />
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label htmlFor="email">メールアドレス</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder=""
                        defaultValue=""
                        value={u.email}
                        onChange={this.handleEmail}
                      />
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label htmlFor="initial_password">初期パスワード</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">{u.initial_password}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-1">
                    <label htmlFor="status">ステータス</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <select
                        className="form-control select2bs4"
                        style={{ width: "100%" }}
                        defaultValue="1"
                        value={u.status}
                        onChange={this.handleStatus}
                      >
                        <option value="1">有効</option>
                        <option value="0">無効</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/*
              <div className="row">
                <div className="col-lg-1">
                  <label htmlFor="">最終更新日時</label>
                </div>
                <div className="col-lg-2">
                  2022/01/10 10:10:10
                </div>
                <div className="col-lg-1">
                  <label htmlFor="">最終更新者</label>
                </div>
                <div className="col-lg-2">
                </div>
              </div>
              */}
              </div>
              <div className="card-footer pt-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group clearfix mb-0">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.updateUser}
                      >
                        登録
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          this.props.navigate("/user_list");
                        }}
                      >
                        戻る
                      </button>
                    </div>
                  </div>
                  <div className="col-6 text-right">
                    {/*
                    <div className="form-group clearfix mb-0">
                      {this.state.userId ? (
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={this.resetUserPassword}
                        >
                          パスワード初期化
                        </button>
                      ) : (
                        <></>
                      )}
                      &nbsp;
                      {<button type="button" className="btn btn-danger">削除</button>}
                    </div>
                     */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}

        {/* /.content-wrapper */}
      </div>
    );
  }
}
