import { API, Auth } from "aws-amplify";
import React from "react";
import { Button } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import config from "../config.json";
import Constant from "../Constant.js";

export default function CourseEdit(props) {
  const params = useParams();
  const navigate = useNavigate();
  return <CourseEditComp {...props} navigate={navigate} params={params} />;
}

class CourseEditComp extends React.Component {
  constructor(props, params) {
    super(props);
    this.state = {
      api_name: config.stage,
      id: this.props.params.courseId,
      course: {},
      request: {},
    };

    if (this.props.params.courseId) {
      this.readCourse();
    }
  }

  readCourse = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        timestamp: new Date().getTime(),
      },
    };

    await API.get(this.state.api_name, "/courses/" + this.state.id, request)
      .then((r) => {
        console.log(r);
        this.setState({
          course: r,
        });
      })
      .catch((e) => {
        toast.error("コースデータの取得エラー");
      });
  };

  createCourse = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      body: this.state.course,
    };

    await API.post(this.state.api_name, "/courses", request)
      .then((r) => {
        console.log(r);
        toast.success("コースデータを登録しました");
        window.location.href = "/course_edit/" + r.id;
      })
      .catch((e) => {
        toast.error("コースデータの登録エラー");
      });
  };

  updateCourse = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      body: this.state.course,
    };

    await API.put(this.state.api_name, "/courses/" + this.state.id, request)
      .then((r) => {
        console.log(r);
        this.readCourse();
        toast.success("コースデータを登録しました");
      })
      .catch((e) => {
        toast.error("コースデータの登録エラー");
      });
  };

  calcTotal() {
    const course = this.state.course;
    var total_num = 0;
    var total_emg_num = 0;
    total_num += parseInt(course.round_1_num) || 0;
    total_num += parseInt(course.round_2_num) || 0;
    total_num += parseInt(course.round_3_num) || 0;
    total_num += parseInt(course.round_4_num) || 0;
    total_num += parseInt(course.round_5_num) || 0;
    total_num += parseInt(course.new_round_1_num) || 0;
    total_num += parseInt(course.new_round_2_num) || 0;
    total_num += parseInt(course.new_round_3_num) || 0;
    total_num += parseInt(course.new_round_4_num) || 0;
    total_num += parseInt(course.new_round_5_num) || 0;
    total_num += parseInt(course.maru_num) || 0;
    total_emg_num += parseInt(course.round_1_emg_num) || 0;
    total_emg_num += parseInt(course.round_2_emg_num) || 0;
    total_emg_num += parseInt(course.round_3_emg_num) || 0;
    total_emg_num += parseInt(course.round_4_emg_num) || 0;
    total_emg_num += parseInt(course.round_5_emg_num) || 0;
    total_emg_num += parseInt(course.new_round_1_emg_num) || 0;
    total_emg_num += parseInt(course.new_round_2_emg_num) || 0;
    total_emg_num += parseInt(course.new_round_3_emg_num) || 0;
    total_emg_num += parseInt(course.new_round_4_emg_num) || 0;
    total_emg_num += parseInt(course.new_round_5_emg_num) || 0;
    total_emg_num += parseInt(course.maru_emg_num) || 0;
    course.total_num = total_num;
    course.total_emg_num = total_emg_num;
    this.setState({
      course: course,
    });
  }

  deleteCourse = async () => {
    // TODO
  };

  updateCourseOnClick = async (event) => {
    if (!this.state.course.name) {
      alert("コース名を入力してください。");
      return false;
    }
    if (!this.state.course.site_id) {
      alert("検査場を選択してください。");
      return false;
    }
    if (!this.state.course.car_type) {
      alert("自動車区分を選択してください。");
      return false;
    }
    if (this.state.id) {
      this.updateCourse();
    } else {
      this.createCourse();
    }
  };

  deleteCorseOnClick = async (event) => {
    this.deleteCourse();
  };

  render() {
    console.log("render");

    return (
      <div className="content-wrapper">
        <Toaster />
        {/* Content Wrapper. Contains page content */}
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">コースマスター</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/*Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-1">
                    <label for="name">コース名</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder=""
                        value={this.state.course.name}
                        onChange={(event) => {
                          const c = this.state.course;
                          c.name = event.target.value;
                          this.setState({
                            course: c,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label for="site_id">検査場</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <select
                        className="form-control select2bs4"
                        style={{ width: "100%" }}
                        defaultValue=""
                        value={this.state.course.site_id}
                        disabled={this.state.id ? true : false}
                        onChange={(event) => {
                          const c = this.state.course;
                          c.site_id = event.target.value;
                          this.setState({
                            course: c,
                          });
                        }}
                      >
                        <option value=""></option>
                        {Constant.SITE_LIST.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <label for="car_type">自動車区分</label>
                  </div>
                  <div className="col-lg-5">
                    <div className="form-group">
                      <div
                        className="form-check-inline"
                        value={this.state.course.car_type}
                        onChange={(event) => {
                          const c = this.state.course;
                          c.car_type = event.target.value;
                          this.setState({
                            course: c,
                          });
                        }}
                      >
                        <div className="icheck-primary">
                          <input
                            type="radio"
                            className="form-control"
                            id="car_type_normal"
                            name="car_type"
                            defaultChecked
                            value="1"
                            checked={
                              this.state.course.car_type == Constant.CAR_TYPE_1
                                ? true
                                : false
                            }
                            disabled={this.state.id ? true : false}
                          />
                          <label htmlFor="car_type_normal">普通自動車</label>
                        </div>
                        <div className="icheck-primary ml-2">
                          <input
                            type="radio"
                            className="form-control"
                            id="car_type_small"
                            name="car_type"
                            value="2"
                            checked={
                              this.state.course.car_type == Constant.CAR_TYPE_2
                                ? true
                                : false
                            }
                            disabled={this.state.id ? true : false}
                          />
                          <label htmlFor="car_type_small">軽自動車</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row table-responsive">
                  <table className="table table-sm table-bordered text-nowrap">
                    <thead>
                      <tr>
                        <th
                          className="table-secondary"
                          style={{ width: "15%" }}
                        >
                          種別
                        </th>
                        <th
                          className="table-secondary"
                          style={{ width: "15%" }}
                        >
                          ラウンド
                        </th>
                        <th
                          className="table-secondary"
                          style={{ width: "10%" }}
                        >
                          予約台数
                        </th>
                        <th
                          className="table-secondary"
                          style={{ width: "10%" }}
                        >
                          緊急台数
                        </th>
                        <th
                          className="table-secondary"
                          style={{ width: "50%" }}
                        >
                          備考
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th rowSpan="5" className="table-secondary">
                          継続
                        </th>
                        <th className="table-secondary">1ラウンド</th>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.round_1_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_1_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.round_1_emg_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_1_emg_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="50"
                              value={this.state.course.round_1_remark}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_1_remark = event.target.value;
                                this.setState({
                                  course: c,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">2ラウンド</th>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.round_2_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_2_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.round_2_emg_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_2_emg_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="50"
                              value={this.state.course.round_2_remark}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_2_remark = event.target.value;
                                this.setState({
                                  course: c,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">3ラウンド</th>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.round_3_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_3_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.round_3_emg_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_3_emg_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="50"
                              value={this.state.course.round_3_remark}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_3_remark = event.target.value;
                                this.setState({
                                  course: c,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">4ラウンド</th>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.round_4_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_4_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.round_4_emg_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_4_emg_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="50"
                              value={this.state.course.round_4_remark}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_4_remark = event.target.value;
                                this.setState({
                                  course: c,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">5ラウンド</th>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.round_5_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_5_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.round_5_emg_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_5_emg_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="50"
                              value={this.state.course.round_5_remark}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.round_5_remark = event.target.value;
                                this.setState({
                                  course: c,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th rowSpan="5" className="table-secondary">
                          新車新規
                        </th>
                        <th className="table-secondary">1ラウンド</th>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.new_round_1_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_1_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.new_round_1_emg_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_1_emg_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="50"
                              value={this.state.course.new_round_1_remark}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_1_remark = event.target.value;
                                this.setState({
                                  course: c,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">2ラウンド</th>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.new_round_2_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_2_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.new_round_2_emg_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_2_emg_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="50"
                              value={this.state.course.new_round_2_remark}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_2_remark = event.target.value;
                                this.setState({
                                  course: c,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">3ラウンド</th>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.new_round_3_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_3_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.new_round_3_emg_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_3_emg_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="50"
                              value={this.state.course.new_round_3_remark}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_3_remark = event.target.value;
                                this.setState({
                                  course: c,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">4ラウンド</th>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.new_round_4_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_4_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.new_round_4_emg_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_4_emg_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="50"
                              value={this.state.course.new_round_4_remark}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_4_remark = event.target.value;
                                this.setState({
                                  course: c,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th className="table-secondary">5ラウンド</th>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.new_round_5_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_5_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.new_round_5_emg_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_5_emg_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="50"
                              value={this.state.course.new_round_5_remark}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.new_round_5_remark = event.target.value;
                                this.setState({
                                  course: c,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th colSpan="2" className="table-secondary">
                          マルチュウ
                        </th>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.maru_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.maru_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="2"
                              value={this.state.course.maru_emg_num}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.maru_emg_num = event.target.value;
                                this.setState({
                                  course: c,
                                });
                                this.calcTotal();
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="50"
                              value={this.state.course.maru_remark}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.maru_remark = event.target.value;
                                this.setState({
                                  course: c,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th colSpan="2" className="table-secondary">
                          合計
                        </th>
                        <td>
                          <div className="form-group">
                            {this.state.course.total_num}
                          </div>
                        </td>
                        <td>
                          <div className="form-group">
                            {this.state.course.total_emg_num}
                          </div>
                        </td>
                        <td>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="50"
                              value={this.state.course.total_remark}
                              onChange={(event) => {
                                const c = this.state.course;
                                c.total_remark = event.target.value;
                                this.setState({
                                  course: c,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/*
                <div className="row">
                  <div className="col-lg-1">
                    <label for="">最終更新日時</label>
                  </div>
                  <div className="col-lg-2">2022/01/10 10:10:10</div>
                  <div className="col-lg-1">
                    <label for="">最終更新者</label>
                  </div>
                  <div className="col-lg-2">担当者A</div>
                </div>
                */}
              </div>
              <div className="card-footer pt-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group clearfix mb-0">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.updateCourseOnClick}
                      >
                        登録
                      </button>
                      &nbsp;
                      <Button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          this.props.navigate("/course_list");
                        }}
                      >
                        戻る
                      </Button>
                    </div>
                  </div>
                  <div className="col-6 text-right">
                    {/*
                    <div className="form-group clearfix mb-0">
                      <button type="button" className="btn btn-danger">
                        削除
                      </button>
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
        {/* /.content-wrapper */}
      </div>
    );
  }
}
