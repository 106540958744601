import { API, Auth } from "aws-amplify";
import React from "react";
import { Button } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../components/Modal";
import config from "../config.json";
import Constant from "../Constant";

import FullCalendar from "@fullcalendar/react";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

export default function SiteEdit(props) {
  const params = useParams();
  const navigate = useNavigate();
  return <SiteEditComp {...props} navigate={navigate} params={params} />;
}
class SiteEditComp extends React.Component {
  constructor(props, params) {
    super(props);
    this.state = {
      api_name: config.stage,
      id: this.props.params.siteId,
      name: null,
      mail_to_normal: null,
      mail_to_light: null,
      request: {},
      site_wdays: {},
      update_site_wday: {
        SiteWday: {
          id: null,
          site_id: null,
          car_type: null,
          wday: null,
          business_form: null,
          course_id: null,
          round_start: null,
          round_end: null,
        },
        site_name: null,
        wday_name: null,
        car_type_name: null,
      },
      site_calendars: {},
      create_site_calendar: {
        site_id: this.props.params.siteId,
        car_type: 1,
        target_date: null,
        business_form: 1,
        course_id: null,
        round_start: null,
        round_end: null,
      },
      update_site_calendar: {
        id: null,
        site_id: null,
        car_type: null,
        target_date: null,
        business_form: null,
        course_id: null,
        round_start: null,
        round_end: null,
      },
      courses: [],
      course_select_html: null,
      course_select_car_type_1_html: null,
      course_select_car_type_2_html: null,
      update_wday_modal_show: false,
      create_calendar_modal_show: false,
      update_calendar_modal_show: false,
      setLoading: this.props.setLoading,
    };

    this.readSite();
    this.getSiteWdays();
    this.getSiteCalendars();
    this.getCourses();
  }

  getFormatDate(date) {
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  readSite = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        timestamp: new Date().getTime(),
      },
    };

    await API.get(this.state.api_name, "/sites/" + this.state.id, request)
      .then((r) => {
        console.log(r);
        this.setState({
          name: r.name,
          mail_to_normal: r.mail_to_normal,
          mail_to_light: r.mail_to_light,
        });
      })
      .catch((e) => {
        toast.error("検査場データの取得エラー");
      });
  };

  getSiteWdays = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        site_id: this.state.id,
      },
    };

    await API.get(this.state.api_name, "/site_wdays", request)
      .then((r) => {
        this.setState({
          request: request,
          site_wdays: r,
        });
      })
      .catch((e) => {
        toast.error("業務形態設定（曜日別）データの取得エラー");
      });
  };

  getSiteCalendars = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        site_id: this.state.id,
      },
    };

    await API.get(this.state.api_name, "/site_calendars", request)
      .then((r) => {
        console.log(r);
        const site_calendars = [];
        for (const i in r) {
          const sc = r[i];
          console.log(sc);
          site_calendars.push({
            id: sc.SiteCalendar.id,
            car_type: sc.SiteCalendar.car_type,
            title:
              "【" +
              sc.car_type_name +
              "】" +
              (sc.course_name ? sc.course_name : "休"),
            start: sc.SiteCalendar.target_date,
            color: sc.SiteCalendar.business_form === 1 ? "" : "orange",
          });
        }
        console.log(site_calendars);
        this.setState({
          request: request,
          site_calendars: site_calendars,
        });
      })
      .catch((e) => {
        toast.error("業務形態設定（カレンダー）データの取得エラー");
      });
  };

  getCourses = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        site_id: this.state.id,
      },
    };

    await API.get(this.state.api_name, "/courses", request)
      .then((r) => {
        this.setState({
          request: request,
          courses: r,
        });
      })
      .catch((e) => {
        toast.error("コースデータの取得エラー");
      });
  };

  updateSite = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      body: {
        id: this.state.id,
        name: this.state.name,
        mail_to_normal: this.state.mail_to_normal,
        mail_to_light: this.state.mail_to_light,
      },
    };

    await API.put(this.state.api_name, "/sites/" + this.state.id, request)
      .then((r) => {
        console.log(r);
        this.readSite();
        toast.success("検査場データを登録しました");
      })
      .catch((e) => {
        toast.error("検査場データの登録エラー");
      });
  };

  readSiteWday = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        timestamp: new Date().getTime(),
      },
    };

    await API.get(
      this.state.api_name,
      "/site_wdays/" + this.state.update_site_wday.SiteWday.id,
      request
    )
      .then((r) => {
        console.log(r);
        this.setState({
          update_site_wday: r,
        });
      })
      .catch((e) => {
        toast.error("業務形態設定（曜日別）データの取得エラー");
      });
  };

  updateSiteWday = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      body: {
        id: this.state.update_site_wday.SiteWday.id,
        business_form: this.state.update_site_wday.SiteWday.business_form,
        course_id: this.state.update_site_wday.SiteWday.course_id,
        round_end: this.state.update_site_wday.SiteWday.round_end,
      },
    };

    await API.put(
      this.state.api_name,
      "/site_wdays/" + this.state.update_site_wday.SiteWday.id,
      request
    )
      .then((r) => {
        console.log(r);
        this.getSiteWdays();
        this.handleUpdateWdayModalHide();
        toast.success("業務形態設定（曜日別）データを登録しました");
      })
      .catch((e) => {
        toast.error("業務形態設定（曜日別）データの登録エラー");
      });
  };

  readSiteCalendar = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        timestamp: new Date().getTime(),
      },
    };

    await API.get(
      this.state.api_name,
      "/site_calendars/" + this.state.update_site_calendar.id,
      request
    )
      .then((r) => {
        console.log(r);
        this.setState({
          update_site_calendar: r,
        });
      })
      .catch((e) => {
        toast.error("業務形態設定（カレンダー）データの取得エラー");
      });
  };

  createSiteCalendar = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      body: {
        site_id: this.state.id,
        target_date: this.state.create_site_calendar.target_date,
        car_type: this.state.create_site_calendar.car_type,
        business_form: this.state.create_site_calendar.business_form,
        course_id: this.state.create_site_calendar.course_id,
        round_end: this.state.create_site_calendar.round_end,
      },
    };

    await API.post(this.state.api_name, "/site_calendars", request)
      .then((r) => {
        console.log(r);
        this.getSiteCalendars();
        this.handleCreateCalendarModalHide();
        toast.success("業務形態設定（カレンダー）データを登録しました");
      })
      .catch((e) => {
        toast.error("業務形態設定（カレンダー）データの登録エラー");
      });
  };

  updateSiteCalendar = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      body: {
        id: this.state.update_site_calendar.id,
        car_type: this.state.update_site_calendar.car_type,
        business_form: this.state.update_site_calendar.business_form,
        course_id: this.state.update_site_calendar.course_id,
        round_end: this.state.update_site_calendar.round_end,
      },
    };

    await API.put(
      this.state.api_name,
      "/site_calendars/" + this.state.update_site_calendar.id,
      request
    )
      .then((r) => {
        console.log(r);
        this.getSiteCalendars();
        this.handleUpdateCalendarModalHide();
        toast.success("業務形態設定（カレンダー）データを登録しました");
      })
      .catch((e) => {
        toast.error("業務形態設定（カレンダー）データの登録エラー");
      });
  };

  deleteSiteCalendar = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      body: {
        id: this.state.update_site_calendar.id,
      },
    };

    await API.del(
      this.state.api_name,
      "/site_calendars/" + this.state.update_site_calendar.id,
      request
    )
      .then((r) => {
        console.log(r);
        this.getSiteCalendars();
        this.handleUpdateCalendarModalHide();
        toast.success("業務形態設定（カレンダー）データを削除しました");
      })
      .catch((e) => {
        toast.error("業務形態設定（カレンダー）データの削除エラー");
      });
  };

  handleName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleMailToNormal = (event) => {
    this.setState({
      mail_to_normal: event.target.value,
    });
  };

  handleMailToLight = (event) => {
    this.setState({
      mail_to_light: event.target.value,
    });
  };

  handleUpdateWdayBusinessForm = (event) => {
    const update_site_wday = this.state.update_site_wday;
    update_site_wday.SiteWday.business_form = event.target.value;
    this.setState({
      update_site_wday: update_site_wday,
    });
  };

  handleUpdateWdayCourseId = (event) => {
    const update_site_wday = this.state.update_site_wday;
    update_site_wday.SiteWday.course_id = event.target.value;
    this.setState({
      update_site_wday: update_site_wday,
    });
  };

  handleUpdateWdayRoundEnd = (event) => {
    const update_site_wday = this.state.update_site_wday;
    update_site_wday.SiteWday.round_end = event.target.value;
    this.setState({
      update_site_wday: update_site_wday,
    });
  };

  handleCreateCalendarCarType = (event) => {
    const create_site_calendar = this.state.create_site_calendar;
    create_site_calendar.car_type = event.target.value;
    let course_select_html = this.state.course_select_car_type_1_html;
    if (create_site_calendar.car_type == Constant.CAR_TYPE_2) {
      course_select_html = this.state.course_select_car_type_2_html;
    }
    this.setState({
      create_site_calendar: create_site_calendar,
      course_select_html: course_select_html,
    });
  };

  handleCreateCalendarBusinessForm = (event) => {
    const create_site_calendar = this.state.create_site_calendar;
    create_site_calendar.business_form = event.target.value;
    this.setState({
      create_site_calendar: create_site_calendar,
    });
  };

  handleCreateCalendarCourseId = (event) => {
    const create_site_calendar = this.state.create_site_calendar;
    create_site_calendar.course_id = event.target.value;
    this.setState({
      create_site_calendar: create_site_calendar,
    });
  };

  handleCreateCalendarRoundEnd = (event) => {
    const create_site_calendar = this.state.create_site_calendar;
    create_site_calendar.round_end = event.target.value;
    this.setState({
      create_site_calendar: create_site_calendar,
    });
  };

  handleUpdateCalendarCarType = (event) => {
    const update_site_calendar = this.state.update_site_calendar;
    update_site_calendar.car_type = event.target.value;
    this.setState({
      update_site_calendar: update_site_calendar,
    });
  };

  handleUpdateCalendarBusinessForm = (event) => {
    const update_site_calendar = this.state.update_site_calendar;
    update_site_calendar.business_form = event.target.value;
    this.setState({
      update_site_calendar: update_site_calendar,
    });
  };

  handleUpdateCalendarCourseId = (event) => {
    const update_site_calendar = this.state.update_site_calendar;
    update_site_calendar.course_id = event.target.value;
    this.setState({
      update_site_calendar: update_site_calendar,
    });
  };

  handleUpdateCalendarRoundEnd = (event) => {
    const update_site_calendar = this.state.update_site_calendar;
    update_site_calendar.round_end = event.target.value;
    this.setState({
      update_site_calendar: update_site_calendar,
    });
  };

  handleUpdateWdayModalShow = (data) => {
    //this.state.setLoading(true);
    const update_site_wday = this.state.update_site_wday;
    update_site_wday.SiteWday.id = data.id;
    let course_select_html = this.state.course_select_car_type_1_html;
    if (data.car_type == Constant.CAR_TYPE_2) {
      course_select_html = this.state.course_select_car_type_2_html;
    }
    this.readSiteWday();
    this.setState({
      update_site_wday: update_site_wday,
      course_select_html: course_select_html,
    });
    this.setState({
      update_wday_modal_show: true,
    });
    //this.state.setLoading(false);
  };

  handleUpdateWdayModalHide = () => {
    this.setState({
      update_wday_modal_show: false,
    });
  };

  handleCreateCalendarModalShow = (event) => {
    const create_site_calendar = this.state.create_site_calendar;
    create_site_calendar.target_date = event.dateStr;
    create_site_calendar.car_type = 1;
    create_site_calendar.business_form = 1;
    create_site_calendar.course_id = null;
    create_site_calendar.round_start = null;
    create_site_calendar.round_end = null;
    let course_select_html = this.state.course_select_car_type_1_html;
    if (create_site_calendar.car_type == Constant.CAR_TYPE_2) {
      course_select_html = this.state.course_select_car_type_2_html;
    }
    this.setState({
      create_site_calendar: create_site_calendar,
      course_select_html: course_select_html,
    });
    this.setState({
      create_calendar_modal_show: true,
    });
  };

  handleCreateCalendarModalHide = (event) => {
    this.setState({
      create_calendar_modal_show: false,
    });
  };

  handleUpdateCalendarModalShow = (event) => {
    const update_site_calendar = this.state.update_site_calendar;
    update_site_calendar.id = event.event.id;
    let course_select_html = this.state.course_select_car_type_1_html;
    if (event.event.car_type == Constant.CAR_TYPE_2) {
      course_select_html = this.state.course_select_car_type_2_html;
    }
    this.readSiteCalendar();
    this.setState({
      update_site_calendar: update_site_calendar,
      course_select_html: course_select_html,
    });
    this.setState({
      update_calendar_modal_show: true,
    });
  };

  handleUpdateCalendarModalHide = (event) => {
    this.setState({
      update_calendar_modal_show: false,
    });
  };

  handleDateClick = async (event) => {
    console.log(event);
  };

  handleEventClick = async (event) => {
    console.log(event);
    console.log(event.event.id);
  };

  updateSiteOnClick = async (event) => {
    this.updateSite();
  };

  updateSiteWdayOnClick = async (event) => {
    if (
      this.state.update_site_wday.SiteWday.business_form == "1" &&
      (!this.state.update_site_wday.SiteWday.course_id ||
        !this.state.update_site_wday.SiteWday.round_end)
    ) {
      alert("営業日の場合は、コースとラウンドを選択してください。");
      return false;
    }
    this.updateSiteWday();
  };

  createSiteCalendarOnClick = async (event) => {
    if (
      this.state.create_site_calendar.business_form == "1" &&
      (!this.state.create_site_calendar.course_id ||
        !this.state.create_site_calendar.round_end)
    ) {
      alert("営業日の場合は、コースとラウンドを選択してください。");
      return false;
    }
    this.createSiteCalendar();
  };

  updateSiteCalendarOnClick = async (event) => {
    this.updateSiteCalendar();
  };

  deleteSiteCalendarOnClick = async (event) => {
    this.deleteSiteCalendar();
  };

  render() {
    console.log("render");
    let site_wday_html_list = [];
    let site_calendar_html_list = [];

    console.log(this.state.site_wdays);
    for (let i in this.state.site_wdays) {
      let sw = this.state.site_wdays[i];
      let key = sw.SiteWday.id;
      let round_range = "";
      if (sw.SiteWday.round_start && sw.SiteWday.round_end) {
        round_range = sw.SiteWday.round_start + " 〜 " + sw.SiteWday.round_end;
      }
      site_wday_html_list.push(
        <tr>
          <td key={key} className="text-center" style={{ width: "75px" }}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() =>
                this.handleUpdateWdayModalShow({
                  id: key,
                  car_type: sw.SiteWday.car_type,
                })
              }
            >
              編集
            </button>
          </td>
          <td>{sw.wday_name}</td>
          <td>{sw.car_type_name}</td>
          <td>{sw.business_form_name}</td>
          <td>{sw.course_name}</td>
          <td>{round_range}</td>
        </tr>
      );
    }

    for (let i in this.state.site_calendars) {
      let sc = this.state.site_calendars[i];
      site_calendar_html_list.push();
    }

    let course_select_car_type_1_html = [<option value=""></option>];
    for (let i in this.state.courses) {
      let c = this.state.courses[i];
      if (c.Course.car_type == Constant.CAR_TYPE_1) {
        course_select_car_type_1_html.push(
          <option key={c.Course.id} value={c.Course.id}>
            {c.Course.name}
          </option>
        );
      }
    }
    this.state.course_select_car_type_1_html = course_select_car_type_1_html;
    let course_select_car_type_2_html = [<option value=""></option>];
    for (let i in this.state.courses) {
      let c = this.state.courses[i];
      if (c.Course.car_type == Constant.CAR_TYPE_2) {
        course_select_car_type_2_html.push(
          <option key={c.Course.id} value={c.Course.id}>
            {c.Course.name}
          </option>
        );
      }
    }
    this.state.course_select_car_type_2_html = course_select_car_type_2_html;

    return (
      <div className="content-wrapper">
        <Toaster />
        {/* Content Wrapper. Contains page content */}
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">検査場マスター</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        <Modal
          show={this.state.update_wday_modal_show}
          title="業務形態設定（曜日別）"
          body={
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>曜日</label>
                    <span className="form-text">
                      {this.state.update_site_wday.wday_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>自動車区分</label>
                    <span className="form-text">
                      {this.state.update_site_wday.car_type_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>業務形態</label>
                    <br />
                    <div
                      className="form-check-inline"
                      value={this.state.update_site_wday.SiteWday.business_form}
                      onChange={this.handleUpdateWdayBusinessForm}
                    >
                      <div className="icheck-primary">
                        <input
                          type="radio"
                          className="form-control"
                          id="business_form_1"
                          name="business_form"
                          defaultChecked
                          value="1"
                          checked={
                            this.state.update_site_wday.SiteWday
                              .business_form == Constant.BUSINESS_FORM_1
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="business_form_1">営業日　</label>
                      </div>
                      <div className="icheck-primary">
                        <input
                          type="radio"
                          className="form-control"
                          id="business_form_2"
                          name="business_form"
                          value="2"
                          checked={
                            this.state.update_site_wday.SiteWday
                              .business_form == Constant.BUSINESS_FORM_2
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="business_form_2">休日　</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>コース</label>
                    <select
                      className="form-control select2bs4"
                      style={{ width: "100%" }}
                      defaultValue=""
                      value={
                        this.state.update_site_wday.SiteWday.course_id || ""
                      }
                      onChange={this.handleUpdateWdayCourseId}
                    >
                      {this.state.course_select_html}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>ラウンド</label>
                    <br />
                    <div className="form-inline">
                      <span className="form-text pr-2">1 ～</span>
                      <select
                        className="form-control select2bs4"
                        style={{ width: "20%" }}
                        defaultValue=""
                        value={
                          this.state.update_site_wday.SiteWday.round_end || ""
                        }
                        onChange={this.handleUpdateWdayRoundEnd}
                      >
                        <option value=""></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/*}
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>最終更新日時</label>
                    <span className="form-text">2022/01/10 10:10:10</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>最終更新者</label>
                    <span className="form-text">担当者A</span>
                  </div>
                </div>
              </div>
              */}
            </>
          }
          footer={
            <>
              <Button
                type="button"
                variant="primary"
                className="btn btn-primary mr-auto"
                onClick={this.updateSiteWdayOnClick}
              >
                登録
              </Button>
              &nbsp;
              <Button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  this.props.navigate("/site_list");
                }}
              >
                戻る
              </Button>
            </>
          }
          onHide={this.handleUpdateWdayModalHide}
        />
        <Modal
          show={this.state.create_calendar_modal_show}
          title="業務形態設定（カレンダー）"
          body={
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>対象日</label>
                    <span className="form-text">
                      {this.state.create_site_calendar.target_date}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>自動車区分</label>
                    <span className="form-text">
                      <div
                        className="form-check-inline"
                        value={this.state.create_site_calendar.car_type}
                        onChange={this.handleCreateCalendarCarType}
                      >
                        <div className="icheck-primary">
                          <input
                            type="radio"
                            className="form-control"
                            id="car_type_1"
                            name="car_type"
                            defaultChecked
                            value="1"
                            checked={
                              this.state.create_site_calendar.car_type ==
                              Constant.CAR_TYPE_1
                                ? true
                                : false
                            }
                          />
                          <label htmlFor="car_type_1">普通自動車　</label>
                        </div>
                        <div className="icheck-primary">
                          <input
                            type="radio"
                            className="form-control"
                            id="car_type_2"
                            name="car_type"
                            value="2"
                            checked={
                              this.state.create_site_calendar.car_type ==
                              Constant.CAR_TYPE_2
                                ? true
                                : false
                            }
                          />
                          <label htmlFor="car_type_2">軽自動車　</label>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>業務形態</label>
                    <br />
                    <div
                      className="form-check-inline"
                      value={this.state.create_site_calendar.business_form}
                      onChange={this.handleCreateCalendarBusinessForm}
                    >
                      <div className="icheck-primary">
                        <input
                          type="radio"
                          className="form-control"
                          id="business_form_1"
                          name="business_form"
                          defaultChecked
                          value="1"
                          checked={
                            this.state.create_site_calendar.business_form ==
                            Constant.BUSINESS_FORM_1
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="business_form_1">営業日　</label>
                      </div>
                      <div className="icheck-primary">
                        <input
                          type="radio"
                          className="form-control"
                          id="business_form_2"
                          name="business_form"
                          value="2"
                          checked={
                            this.state.create_site_calendar.business_form ==
                            Constant.BUSINESS_FORM_2
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="business_form_2">休日　</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>コース</label>
                    <select
                      className="form-control select2bs4"
                      style={{ width: "100%" }}
                      defaultValue=""
                      value={this.state.create_site_calendar.course_id || ""}
                      onChange={this.handleCreateCalendarCourseId}
                    >
                      {this.state.course_select_html}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>ラウンド</label>
                    <br />
                    <div className="form-inline">
                      <span className="form-text pr-2">1 ～</span>
                      <select
                        className="form-control select2bs4"
                        style={{ width: "20%" }}
                        defaultValue=""
                        value={this.state.create_site_calendar.round_end || ""}
                        onChange={this.handleCreateCalendarRoundEnd}
                      >
                        <option value=""></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/*}
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>最終更新日時</label>
                    <span className="form-text">2022/01/10 10:10:10</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>最終更新者</label>
                    <span className="form-text">担当者A</span>
                  </div>
                </div>
              </div>
              */}
            </>
          }
          footer={
            <>
              <Button
                type="button"
                variant="primary"
                className="btn btn-primary mr-auto"
                onClick={this.createSiteCalendarOnClick}
              >
                登録
              </Button>
            </>
          }
          onHide={this.handleCreateCalendarModalHide}
        />
        <Modal
          show={this.state.update_calendar_modal_show}
          title="業務形態設定（カレンダー）"
          body={
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>対象日</label>
                    <span className="form-text">
                      {this.state.update_site_calendar.target_date}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>自動車区分</label>
                    <span className="form-text">
                      <div
                        className="form-check-inline"
                        value={this.state.update_site_calendar.car_type}
                        onChange={this.handleUpdateCalendarCarType}
                      >
                        <div className="icheck-primary">
                          <input
                            type="radio"
                            className="form-control"
                            id="car_type_1"
                            name="car_type"
                            defaultChecked
                            value="1"
                            checked={
                              this.state.update_site_calendar.car_type ==
                              Constant.CAR_TYPE_1
                                ? true
                                : false
                            }
                          />
                          <label htmlFor="car_type_1">普通自動車　</label>
                        </div>
                        <div className="icheck-primary">
                          <input
                            type="radio"
                            className="form-control"
                            id="car_type_2"
                            name="car_type"
                            value="2"
                            checked={
                              this.state.update_site_calendar.car_type ==
                              Constant.CAR_TYPE_2
                                ? true
                                : false
                            }
                          />
                          <label htmlFor="car_type_2">軽自動車　</label>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>業務形態</label>
                    <br />
                    <div
                      className="form-check-inline"
                      value={this.state.update_site_calendar.business_form}
                      onChange={this.handleUpdateCalendarBusinessForm}
                    >
                      <div className="icheck-primary">
                        <input
                          type="radio"
                          className="form-control"
                          id="business_form_1"
                          name="business_form"
                          defaultChecked
                          value="1"
                          checked={
                            this.state.update_site_calendar.business_form ==
                            Constant.BUSINESS_FORM_1
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="business_form_1">営業日　</label>
                      </div>
                      <div className="icheck-primary">
                        <input
                          type="radio"
                          className="form-control"
                          id="business_form_2"
                          name="business_form"
                          value="2"
                          checked={
                            this.state.update_site_calendar.business_form ==
                            Constant.BUSINESS_FORM_2
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="business_form_2">休日　</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>コース</label>
                    <select
                      className="form-control select2bs4"
                      style={{ width: "100%" }}
                      defaultValue=""
                      value={this.state.update_site_calendar.course_id || ""}
                      onChange={this.handleUpdateCalendarCourseId}
                    >
                      {this.state.course_select_html}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>ラウンド</label>
                    <br />
                    <div className="form-inline">
                      <span className="form-text pr-2">1 ～</span>
                      <select
                        className="form-control select2bs4"
                        style={{ width: "20%" }}
                        defaultValue=""
                        value={this.state.update_site_calendar.round_end || ""}
                        onChange={this.handleUpdateCalendarRoundEnd}
                      >
                        <option value=""></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/*}
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>最終更新日時</label>
                    <span className="form-text">2022/01/10 10:10:10</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>最終更新者</label>
                    <span className="form-text">担当者A</span>
                  </div>
                </div>
              </div>
              */}
            </>
          }
          footer={
            <>
              <Button
                type="button"
                variant="primary"
                className="btn btn-primary mr-auto"
                onClick={this.updateSiteCalendarOnClick}
              >
                登録
              </Button>
              <Button
                type="button"
                variant="danger"
                className="btn btn-danger"
                onClick={this.deleteSiteCalendarOnClick}
              >
                削除
              </Button>
            </>
          }
          onHide={this.handleUpdateCalendarModalHide}
        />

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-1">
                    <label htmlFor="name">検査場名</label>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder=""
                        value={this.state.name}
                        //onChange={this.handleName}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-1">
                    <label htmlFor="mail_to_normal">
                      配信先メールアドレス（全）
                    </label>
                  </div>
                  <div className="col-lg-11">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        id="mail_to_normal"
                        placeholder=""
                        rows="4"
                        value={this.state.mail_to_normal}
                        onChange={this.handleMailToNormal}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-1">
                    <label htmlFor="mail_to_light">
                      配信先メールアドレス（軽のみ）
                    </label>
                  </div>
                  <div className="col-lg-11">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        id="mail_to_light"
                        placeholder=""
                        rows="4"
                        value={this.state.mail_to_light}
                        onChange={this.handleMailToLight}
                      ></textarea>
                    </div>
                  </div>
                </div>
                {/*}
                <div className="row">
                  <div className="col-lg-1">
                    <label htmlFor="">最終更新日時</label>
                  </div>
                  <div className="col-lg-2">2022/01/10 10:10:10</div>
                  <div className="col-lg-1">
                    <label htmlFor="">最終更新者</label>
                  </div>
                  <div className="col-lg-2">担当者A</div>
                </div>
              */}
              </div>
              <div className="card-footer pt-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group clearfix mb-0">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.updateSiteOnClick}
                      >
                        登録
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          this.props.navigate("/site_list");
                        }}
                      >
                        戻る
                      </button>
                    </div>
                  </div>
                  <div className="col-6 text-right">
                    <div className="form-group clearfix mb-0"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h6 style={{ lineHeight: "1.8" }}>
                      業務形態設定（曜日別）
                    </h6>
                  </div>
                  <div className="card-body table-responsive">
                    <table
                      id="business_form_datatable"
                      className="table table-sm table-bordered table-hover table-head-fixed text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th>編集</th>
                          <th>曜日</th>
                          <th>自動車区分</th>
                          <th>業務形態</th>
                          <th>コース</th>
                          <th>ラウンド</th>
                        </tr>
                      </thead>
                      <tbody>{site_wday_html_list}</tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h6 style={{ lineHeight: "1.8" }}>
                      業務形態設定（カレンダー）
                    </h6>
                  </div>
                  <div className="card-body table-responsive">
                    <FullCalendar
                      plugins={[dayGridPlugin, interactionPlugin]}
                      initialView="dayGridMonth"
                      locale="ja"
                      firstDay="1"
                      headerToolbar={{
                        start: "prev",
                        center: "title",
                        end: "next",
                      }}
                      events={this.state.site_calendars}
                      dateClick={this.handleCreateCalendarModalShow}
                      eventClick={this.handleUpdateCalendarModalShow}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}

        {/* /.content-wrapper */}
      </div>
    );
  }
}
